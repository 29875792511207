const soundNames = [
    {
        name: "rain",
    },
    {
        name: "forest",
    },
    {
        name: "waves",
    },
    {
        name: "thunder",
    },
    {
        name: "fire",
    },
    {
        name: "train",
    },
    {
        name: "wind",
    },
    {
        name: "mars",
    },
    {
        name: "restaurant",
    },
    {
        name: "library",
    },
];

export const fetchSounds = async () => {
    return soundNames;
    /* try {
         const auth = getAuth();  // Initialize Firebase Auth
         const currentUser = auth.currentUser;  // Get the current user

         // Ensure the user is authenticated
         if (!currentUser) {
             throw new Error('User is not authenticated');
         }

         // Get the user's ID token
         const token = await currentUser.getIdToken();

         // Fetch sounds from backend with authorization header
         const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/songs`, {
             method: 'GET',
             headers: {
                 'Authorization': `Bearer ${token}`,  // Add Bearer token
                 'Content-Type': 'application/json',
             },
         });

         if (!response.ok) {
             throw new Error(`HTTP error! Status: ${response.status}`);
         }

         const data = await response.json();  // Parse JSON response
         return data;  // Return fetched data
     } catch (error) {
         console.error('Failed to fetch sounds:', error);  // Log any errors
         throw error;  // Re-throw error to be handled by calling function
     }*/
};
