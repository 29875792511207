// combinationService.js

import {getAuth} from 'firebase/auth'; // Import Firebase Auth

const API_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL; // Base URL for your backend API

/**
 * Function to post a combination to the backend using fetch.
 * @param {string} combinationName - The name of the combination.
 * @param {string[]} soundNames - Array of sound names.
 * @returns {Promise} - A promise that resolves to the response of the API call.
 */
export const postCombination = async (combinationName, soundNames) => {
    try {
        const auth = getAuth(); // Initialize Firebase Auth
        const currentUser = auth.currentUser; // Get the current user

        // Ensure the user is authenticated
        if (!currentUser) {
            throw new Error('User is not authenticated');
        }

        // Get the user's ID token
        const token = await currentUser.getIdToken();

        // Define the payload for the request
        const payload = {
            combinationName: combinationName,
            soundNames: soundNames,
        };

        // Post the combination data to backend with authorization header
        const response = await fetch(`${API_BASE_URL}/api/combinations/create`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, // Add Bearer token
                'Content-Type': 'application/json',
                'X-User-Id': currentUser.uid.toString(), // Include the user ID in headers
            },
            body: JSON.stringify(payload), // Convert payload to JSON string
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json(); // Parse JSON response
        return data; // Return the response data

    } catch (error) {
        console.error('Failed to post combination:', error); // Log any errors
        throw error; // Re-throw error to be handled by calling function
    }
};


/**
 * Function to fetch user-created combinations from the backend.
 * @returns {Promise} - A promise that resolves to the user's combinations.
 */
export const fetchUserCombinations = async () => {
    try {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (!currentUser) {
            return []; // Return an empty array if user is not authenticated
        }

        const token = await currentUser.getIdToken();

        // Fetch user combinations from backend with authorization header
        const response = await fetch(`${API_BASE_URL}/api/combinations/user`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'X-User-Id': currentUser.uid.toString(),
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json(); // Parse JSON response
        return data; // Return fetched data

    } catch (error) {
        console.error('Failed to fetch user combinations:', error);
        throw error;
    }
};


export const deleteCombination = async (combinationId) => {
    try {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (!currentUser) {
            throw new Error('User is not authenticated');
        }

        const token = await currentUser.getIdToken();

        // Delete combination from backend with authorization header
        const response = await fetch(`${API_BASE_URL}/api/combinations/${combinationId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'X-User-Id': currentUser.uid.toString(),
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    } catch (error) {
        console.error('Failed to delete combination:', error);
        throw error;
    }
}
