import React, { useState } from 'react';
import PredefinedSessions from './predefined-sessions/PredefinedSessions';
import AddCombinationModal from './create-session/AddCombinationModal';
import './MainComponent.css';

const availableSounds = ['Rain', 'Waves', 'Forest']; // Example sounds

const MainComponent = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customCombinations, setCustomCombinations] = useState([]);

  const activateCombination = (key) => {
    setActiveSection(key);
    // Logic to play sounds
  };

  const handleOpenAddCombinationModal = () => {
    setIsModalOpen(true);
  };

  const handleSaveCombination = (combination) => {
    setCustomCombinations([...customCombinations, combination]);
    // You can save to backend here as well
  };

  return (
    <div>
      <PredefinedSessions
        activateCombination={activateCombination}
        activeSection={activeSection}
        onOpenAddCombinationModal={handleOpenAddCombinationModal}
      />
      <AddCombinationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveCombination}
        availableSounds={availableSounds}
      />
    </div>
  );
};

export default MainComponent;
