import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Redux imports
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import soundReducer from './components/store/soundSlice'; // Adjust the path if needed

// Redux Persist configuration
const persistConfig = {
    key: 'root',
    storage,
    // whitelist: ['sounds'], // You can use this if you want to persist only certain reducers or keys
    // blacklist: ['someOtherReducer'], // Use this if you want to exclude certain reducers from being persisted
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, soundReducer);

// Create Redux store
const store = configureStore({
    reducer: {
        sounds: persistedReducer, // Use the persisted reducer here
        // Include other reducers here if necessary
    },
    // Optional: Add middleware or devTools config
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore actions and paths that contain non-serializable values
                ignoredActions: ['persist/PERSIST'],
                ignoredPaths: ['sounds.playingAudios'], // Ignore the path with Howl instances
            },
        }),
});

// Create a persistor for the store
const persistor = persistStore(store);

// Root element for React
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// Optional: Measure performance
reportWebVitals();