// Newsletter.js

import React, {useState} from 'react';
import './Newsletter.css';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";

const Newsletter = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(String(email).toLowerCase());
    }
    const showToast = (message, type) => {
        Toastify({
            text: message,
            duration: 3000,
            close: true,
            gravity: "top",
            position: "center",
            className: `newsletter__toastify newsletter__toastify--${type}`,
        }).showToast();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateEmail(email)) {
            showToast('Thank you for subscribing!', 'success');
            setEmail('');
            setError('');
        } else {
            showToast('Please enter a valid email address', 'error');
            setError('Please enter a valid email address');
        }
    };

    const handleInputChange = (e) => {
        setEmail(e.target.value);
        if (e.target.value.trim() === '') {
            setError('');
        } else if (!validateEmail(e.target.value)) {
            setError('Please enter a valid email address');
        } else {
            setError('');
        }
    };

    return (
        <div className="newsletter">
            <div className="newsletter__container">
                <header className="newsletter__header">
                    <h1 className="newsletter__title">Join Our Newsletter</h1>
                    <p className="newsletter__subtitle">Stay ahead with the latest updates and exclusive offers</p>
                </header>

                <main className="newsletter__main">
                    <section className="newsletter__benefits">
                        <ul className="newsletter__list">
                            <li className="newsletter__list-item">Exclusive content delivered to your inbox</li>
                            <li className="newsletter__list-item">Early access to new features and products</li>
                            <li className="newsletter__list-item">Special discounts for subscribers only</li>
                        </ul>
                    </section>

                    <section className="newsletter__sign-up-form">
                        <form className="newsletter__form" onSubmit={handleSubmit} noValidate>
                            <div className="newsletter__form-group">
                                <label className="newsletter__label" htmlFor="email">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="you@example.com"
                                    className={`newsletter__input ${error ? 'newsletter__input--invalid' : ''}`}
                                    value={email}
                                    onChange={handleInputChange}
                                    required
                                />
                                {error && <span className="newsletter__error-message">{error}</span>}
                            </div>
                            <button type="submit" className="newsletter__button">Subscribe Now</button>
                        </form>
                    </section>
                </main>

                <footer className="newsletter__footer">
                    <p>&copy; 2024 Limonji. All rights reserved.</p>
                </footer>
            </div>
        </div>
    );
};

export default Newsletter;