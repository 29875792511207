import React from 'react';
import './ConfirmationModal.css';

const ConfirmationModal = ({ isOpen, onConfirm, onCancel, message }) => {
  if (!isOpen) {
    return null;
  }

  return (
      <div className="confirmation-overlay">
        <div className="confirmation-modal">
          <p>{message}</p>
          <div className="confirmation-buttons">
            <button onClick={onConfirm} className="confirm-button">Yes</button>
            <button onClick={onCancel} className="cancel-button">No</button>
          </div>
        </div>
      </div>
  );
};

export default ConfirmationModal;