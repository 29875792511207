import React, { useState, useContext } from 'react';
import { AuthContext } from '../login/AuthContext';
import { Link } from 'react-router-dom';
import './ResetPassword.css';

const ResetPassword = () => {
  const { resetPassword } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async () => {
    try {
      await resetPassword(email);
      setMessage('Password reset email sent! Please check your inbox.');
    } catch (error) {
      console.error('Error resetting password:', error.message);
      setMessage('Error resetting password. Please check the email and try again.');
    }
  };

  return (
    <div className="reset-password-container">
      <h2>Reset Password</h2>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
      />
      <button onClick={handleResetPassword}>Reset Password</button>
      {message && <p>{message}</p>}
      <Link to="/login" className="back-to-signin-link">Back to Sign In</Link>
    </div>
  );
};

export default ResetPassword;
