import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {playSound, stopSound, changeVolume, stopAllSounds, setCurrentSession} from '../store/soundSlice';
import './PredefinedSessions.css';
import './SongList.css';
import './PredefinedSessionsCommon.css';
import AddCombinationModal from '../create-session/AddCombinationModal';
import ConfirmationModal from '../confirmation/ConfirmationModal';
import {toast} from 'react-toastify';
import {fetchSounds} from './service/soundService';
import deleteIcon from '../assets/delete.svg';
import forestIcon from '../assets/forest.svg';
import marsIcon from '../assets/mars.svg';
import rainIcon from '../assets/rain.svg';
import restaurantIcon from '../assets/restaurant.svg';
import trainIcon from '../assets/train.svg';
import wavesIcon from '../assets/waves.svg';
import libraryIcon from '../assets/library.svg';
import thunderIcon from '../assets/thunder.svg';
import windIcon from '../assets/wind.svg';
import fireIcon from '../assets/fire.svg';
import {fetchUserCombinations, deleteCombination} from "../create-session/service/combinationService";

const iconMap = {
    rain: rainIcon,
    forest: forestIcon,
    waves: wavesIcon,
    thunder: thunderIcon,
    fire: fireIcon,
    train: trainIcon,
    wind: windIcon,
    mars: marsIcon,
    restaurant: restaurantIcon,
    library: libraryIcon,
};

const soundContext = require.context('../assets/sounds', false, /\.mp3$/);
const soundMap = soundContext.keys().reduce((map, filePath) => {
    const soundName = filePath.match(/\/([^/]+)\.mp3$/)[1].toLowerCase();
    map[soundName] = soundContext(filePath);
    return map;
}, {});

const initialCombinations = {
    productivity: {
        name: 'Productivity',
        icon: '🌟',
        sounds: ['rain', 'forest'],
    },
    writing: {
        name: 'Writing',
        icon: '✍️',
        sounds: ['library', 'fire'],
    },
    coding: {
        name: 'Coding',
        icon: '💻',
        sounds: ['library', 'wind'],
    },
    focus: {
        name: 'Focus',
        icon: '🎯',
        sounds: ['rain', 'forest'],
    },
    relaxation: {
        name: 'Relaxation',
        icon: '🧘',
        sounds: ['waves', 'forest'],
    },
    sleeping: {
        name: 'Sleeping',
        icon: '😴',
        sounds: ['rain', 'waves'],
    },
    creativity: {
        name: 'Creativity',
        icon: '🎨',
        sounds: ['fire', 'waves'],
    },
    deep_work: {
        name: 'Deep Work',
        icon: '🔬',
        sounds: ['library', 'rain'],
    },
    meditation: {
        name: 'Meditation',
        icon: '🧘‍♂️',
        sounds: ['wind', 'forest'],
    },
    brainstorming: {
        name: 'Brainstorming',
        icon: '💡',
        sounds: ['forest', 'wind'],
    },
    study: {
        name: 'Study',
        icon: '📚',
        sounds: ['library', 'rain'],
    },
    mindfulness: {
        name: 'Mindfulness',
        icon: '🧠',
        sounds: ['forest', 'fire'],
    },
};

const PredefinedSessions = ({activeSection, activateCombination}) => {
    const dispatch = useDispatch();
    const playingAudios = useSelector(state => state.sounds.playingAudios);
    const selectedSounds = useSelector(state => state.sounds.selectedSounds);
    const volumeLevels = useSelector(state => state.sounds.volumeLevels);
    const isPaused = useSelector(state => state.sounds.isPaused);

    const [combinations, setCombinations] = useState(initialCombinations);
    const [userCombinations, setUserCombinations] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [combinationToDelete, setCombinationToDelete] = useState(null);
    const [activeTab, setActiveTab] = useState('predefined');
    const [songs, setSongs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadSoundsAndCombinations = async () => {
            try {
                setLoading(true);
                const [soundsData, userCombinationsData] = await Promise.all([
                    fetchSounds(),
                    fetchUserCombinations()
                ]);

                setSongs(soundsData);

                const formattedUserCombinations = userCombinationsData.reduce((acc, combination) => {
                    acc[combination.combinationName.toLowerCase()] = {
                        id: combination.id,
                        name: combination.combinationName,
                        icon: '🎵',
                        sounds: combination.soundNames,
                    };
                    return acc;
                }, {});

                setUserCombinations(formattedUserCombinations);
            } catch (error) {
                toast.error('Failed to fetch data from the server.', {autoClose: 1000});
            } finally {
                setLoading(false);
            }
        };

        loadSoundsAndCombinations();
    }, []);

    const handleSessionClick = (key) => {
        if (activeSection === key) {
            dispatch(stopAllSounds());
            activateCombination(null);
        } else {
            dispatch(stopAllSounds());
            const selectedCombination = activeTab === 'predefined' ? combinations[key] : userCombinations[key];

            selectedCombination.sounds.forEach((soundName) => {
                const soundFile = soundMap[soundName.toLowerCase()];
                if (soundFile) {
                    dispatch(playSound({soundName: soundName.toLowerCase(), soundFile}));
                } else {
                    console.warn(`Sound not found: ${soundName}`);
                }
            });
            dispatch(setCurrentSession(selectedCombination.name));
            activateCombination(key);
        }
    };

    const handleSoundClick = (sound) => {
        if (playingAudios[sound.name.toLowerCase()]) {
            dispatch(stopSound(sound.name.toLowerCase()));
        } else {
            const soundFile = soundMap[sound.name.toLowerCase()];
            if (soundFile) {
                dispatch(playSound({soundName: sound.name.toLowerCase(), soundFile}));
            }
        }
    };

    const handleDeleteClick = (combinationKey) => {
        setCombinationToDelete(combinationKey);
        setIsConfirmationOpen(true);
    };

    const confirmDeleteSession = async () => {
        dispatch(stopAllSounds());

        try {
            if (activeTab === 'mine') {
                const combinationId = userCombinations[combinationToDelete]?.id;
                if (!combinationId) {
                    toast.error('Invalid combination. Please try again.', {autoClose: 1000});
                    return;
                }

                await deleteCombination(combinationId);
                setUserCombinations((prevUserCombinations) => {
                    const newUserCombinations = {...prevUserCombinations};
                    delete newUserCombinations[combinationToDelete];
                    return newUserCombinations;
                });

                toast.success('Combination deleted successfully!', {autoClose: 1000});

            } else if (activeTab === 'predefined') {
                setCombinations((prevCombinations) => {
                    const newCombinations = {...prevCombinations};
                    delete newCombinations[combinationToDelete];
                    return newCombinations;
                });

                toast.success('Predefined combination deleted successfully!', {autoClose: 1000});
            }

        } catch (error) {
            toast.error('Failed to delete the combination. Please try again.', {autoClose: 1000});
        } finally {
            setIsConfirmationOpen(false);
            setCombinationToDelete(null);
        }
    };

    const handleSaveCombination = (newCombination) => {
        const newSounds = newCombination.sounds
            .map((sound) => songs.find((s) => s.name.toLowerCase() === sound.name.toLowerCase()))
            .filter(Boolean);

        if (newSounds.length > 0) {
            setUserCombinations((prevUserCombinations) => ({
                ...prevUserCombinations,
                [newCombination.name.toLowerCase()]: {
                    name: newCombination.name,
                    icon: '🎵',
                    sounds: newSounds.map(sound => sound.name),
                },
            }));
            toast.success('New sounds combination is saved!', {autoClose: 1000});
        } else {
            toast.error('Failed to save the combination. Sounds are missing.', {autoClose: 1000});
        }
    };

    return (
        <section className="predefined-sessions-and-songs">
            {/* Helmet for Metadata */}
            <Helmet>
                <title>Predefined Sessions - Focus and Relaxation Mixes</title>
                <meta name="description"
                      content="Explore predefined and custom sessions for productivity, relaxation, creativity, and more. Play ambient sounds like rain, forest, and fire to boost focus."/>
                <meta name="keywords"
                      content="Predefined sessions, relaxation, productivity, ambient sounds, focus, music mix, meditation, rain sounds, forest sounds"/>
                <meta property="og:title" content="Predefined Sessions - Focus and Relaxation Mixes"/>
                <meta property="og:description"
                      content="Discover custom sound mixes to improve focus, productivity, relaxation, and mindfulness."/>
                <meta property="og:image" content="/assets/session-thumbnail.png"/>
                <meta property="og:url" content="https://limonji/predefined-sessions"/>
                <link rel="canonical" href="https://limonji.com/"/>
            </Helmet>

            {/* Tab Navigation */}
            <nav className="tabs">
                <button className={`tab ${activeTab === 'predefined' ? 'active' : ''}`}
                        onClick={() => setActiveTab('predefined')}>
                    App Mix
                </button>
                <button className={`tab ${activeTab === 'mine' ? 'active' : ''}`} onClick={() => setActiveTab('mine')}>
                    My Mix
                </button>
            </nav>

            {/* Sessions List */}
            <section className="predefined-sessions">
                {(activeTab === 'predefined' ? Object.keys(combinations) : Object.keys(userCombinations)).map((key) => (
                    <div
                        key={key}
                        className={`session-card ${activeSection === key ? 'active' : ''} ${
                            isPaused && activeSection === key ? 'paused' : ''
                        }`}
                        onClick={() => handleSessionClick(key)}
                        data-tooltip={activeSection === key ? "Click to stop this session" : "Click to play this session"}
                    >
                        <div className="session-icon">
                            {(activeTab === 'predefined' ? combinations[key] : userCombinations[key]).icon}
                        </div>
                        <h3>{(activeTab === 'predefined' ? combinations[key] : userCombinations[key]).name}</h3>
                        {activeTab === 'mine' && (
                            <button
                                className="delete-combination-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteClick(key);
                                }}
                            >
                                <img src={deleteIcon} alt="Delete"/>
                            </button>
                        )}
                    </div>
                ))}

                {/* Add Session Button */}
                {activeTab === 'mine' && (
                    <div className="session-card add-session" onClick={() => setIsModalOpen(true)} data-tooltip="Add a new session">
                        <span className="plus-sign">+</span>
                    </div>
                )}

                <AddCombinationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}
                                     onSave={handleSaveCombination}/>

                <ConfirmationModal
                    isOpen={isConfirmationOpen}
                    onConfirm={confirmDeleteSession}
                    onCancel={() => setIsConfirmationOpen(false)}
                    message="Are you sure you want to delete this combination?"
                />
            </section>

            {/* Song List */}
            <section className="song-list">
                {loading ? (
                    <div className="spinner-square">
                        <div className="square-1 square"></div>
                        <div className="square-2 square"></div>
                        <div className="square-3 square"></div>
                    </div>
                ) : (
                    songs.map((sound) => (
                        <article key={sound.name} className="song-item-container">
                            <div
                                className={`song-item ${selectedSounds.includes(sound.name.toLowerCase()) ? 'active' : ''}`}
                                onClick={() => handleSoundClick(sound)}
                                data-tooltip={selectedSounds.includes(sound.name.toLowerCase()) ? "Click to stop this sound" : "Click to play this sound"}
                            >
                                <img src={iconMap[sound.name.toLowerCase()]} alt={`${sound.name} icon`} loading="lazy"/>
                                <span>{sound.name}</span>
                            </div>

                            {selectedSounds.includes(sound.name.toLowerCase()) && (
                                <div style={{marginTop: '10px'}}>
                                    <input
                                        type="range"
                                        min="0"
                                        max="1"
                                        step="0.01"
                                        value={volumeLevels[sound.name.toLowerCase()]}
                                        onChange={(e) => {
                                            dispatch(changeVolume({
                                                soundName: sound.name.toLowerCase(),
                                                volume: parseFloat(e.target.value)
                                            }));
                                        }}
                                        className="volume-adjuster"
                                        data-tooltip="Adjust volume"
                                    />
                                </div>
                            )}
                        </article>
                    ))
                )}
            </section>

            {/* Additional SEO Content */}
            <section className="additional-content">
                <h2>Discover Ambient Sounds for Better Focus and Relaxation</h2>
                <p>
                    Ambient sounds offers a curated selection of sound combinations designed to enhance your productivity, creativity, and relaxation. Whether you're working, studying, or meditating, our ambient sound mixes—featuring rain, forest, waves, and more—will help you stay focused and calm.
                </p>
                <p>
                    Explore sessions like <strong>Productivity</strong>, where soothing rain and forest sounds create the perfect environment for deep concentration. Or choose <strong>Relaxation</strong> for calming waves and nature sounds to help you unwind.
                </p>
                <h3>Why Ambient Sounds Help with Focus</h3>
                <p>
                    Ambient sounds can create an immersive atmosphere that blocks out distractions, making it easier to concentrate on the task at hand. Research shows that nature sounds, like rain and waves, can have a positive effect on mood and cognitive performance, especially during creative work.
                </p>
                <p>
                    Start using our ambient sound sessions to boost your productivity and relax during breaks. You can also create and save your custom sound combinations to match your preferences.
                </p>
                <h3>Popular Ambient Sounds</h3>
                <ul>
                    <li><strong>Writing:</strong> Soft library sounds with crackling fire to keep your creative juices flowing.</li>
                    <li><strong>Focus:</strong> Rain and forest sounds to help you concentrate on demanding tasks.</li>
                    <li><strong>Relaxation:</strong> Ocean waves paired with forest ambiance for a peaceful break.</li>
                </ul>
            </section>
        </section>
    );
};

export default PredefinedSessions;