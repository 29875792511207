import React, { useEffect, useState } from 'react';
import './AddCombinationModal.css';
import { fetchSounds } from "../predefined-sessions/service/soundService";
import { postCombination } from "./service/combinationService";
import { useNavigate } from 'react-router-dom';
import { auth } from '../login/firebaseConfig';
import {toast} from "react-toastify";

// Dynamically import all SVGs from the /assets directory
const svgContext = require.context('../assets', false, /\.svg$/);
const svgMap = svgContext.keys().reduce((map, filePath) => {
    const soundName = filePath.match(/\/([^/]+)\.svg$/)[1].toLowerCase(); // Extract sound name from file path
    map[soundName] = svgContext(filePath); // Map sound name to the imported SVG file
    return map;
}, {});

const AddCombinationModal = ({ isOpen, onClose, onSave }) => {
    const [combinationName, setCombinationName] = useState('');
    const [selectedSounds, setSelectedSounds] = useState([]);
    const [sounds, setSounds] = useState([]);
    const navigate = useNavigate();

    const isAnonymous = auth.currentUser ? auth.currentUser.isAnonymous : true;

    useEffect(() => {
        const loadSounds = async () => {
            try {
                const data = await fetchSounds();
                setSounds(data);
            } catch (error) {
                toast.error('Failed to fetch songs from the server.', { autoClose: 1000 });
            }
        };

        loadSounds();
    }, []);

    const toggleSound = (sound) => {
        setSelectedSounds((prevSelected) =>
            prevSelected.includes(sound)
                ? prevSelected.filter((s) => s !== sound)
                : [...prevSelected, sound]
        );
    };

    const handleSave = async () => {
        if (combinationName.trim() && selectedSounds.length > 0) {
            try {
                await postCombination(combinationName, selectedSounds.map(sound => sound.name));
                toast.success('Combination saved successfully!', { autoClose: 1000 });
                onSave({ name: combinationName, sounds: selectedSounds });
                resetState();
                onClose();
            } catch (error) {
                toast.error('Failed to save combination.', { autoClose: 1000 });
            }
        }
    };
    const handleSignUpRedirect = () => {
        navigate('/login'); // Redirect to login page
    };

    const resetState = () => {
        setCombinationName('');
        setSelectedSounds([]);
    };

    if (!isOpen) return null;

    return (
        <div className="combination-modal-overlay">
            <div className="combination-modal-content">
                <button
                    className="combination-close-button"
                    onClick={() => {
                        resetState();
                        onClose();
                    }}
                >
                    &times;
                </button>
                <h3 className="combination-title">Create Custom Combination</h3>
                <input
                    type="text"
                    className="combination-input"
                    placeholder="Your combination name here"
                    maxLength={30}
                    value={combinationName}
                    onChange={(e) => setCombinationName(e.target.value)}
                />
                <div className="combination-sound-options">
                    {sounds.map((sound) => (
                        <div
                            key={sound.name}
                            className={`combination-sound-option ${selectedSounds.includes(sound) ? 'selected' : ''}`}
                            onClick={() => toggleSound(sound)}
                        >
                            <img
                                src={svgMap[sound.name.toLowerCase()]}
                                alt={sound.name}
                                className="sound-icon"
                            />
                            {sound.name.charAt(0).toUpperCase() + sound.name.slice(1)}
                        </div>
                    ))}
                </div>
                {isAnonymous ? (
                    <div className="combination-save-disabled">
                        <p className="save-disabled-message">Create an account to save your combinations!</p>
                        <button className="signup-link" onClick={handleSignUpRedirect}>Login</button>
                    </div>
                ) : (
                    <button
                        className="combination-save-button"
                        onClick={handleSave}
                        disabled={!combinationName.trim() || selectedSounds.length === 0}
                    >
                        Save
                    </button>
                )}
            </div>
        </div>
    );
};

export default AddCombinationModal;