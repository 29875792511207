import React, { useContext, useEffect, useState } from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import SignUpSignIn from './SignUpSignIn';
import ScaleLoader from 'react-spinners/ScaleLoader';
import './Login.css';

const Login = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Set up a loading state

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    } else {
      setLoading(false); // Set loading to false once the component is loaded
    }
  }, [currentUser, navigate]);

  const handleGoogleSignIn = async () => {
    setLoading(true); // Show loader when starting the sign-in process
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/'); // Redirect after successful login
    } catch (error) {
      console.error('Google sign-in error:', error.message);

      let errorMessage = 'Something went wrong. Please try again.';
      switch (error.code) {
        case 'auth/invalid-credential':
          errorMessage = 'It seems there was a problem with your credentials. Please try signing in again.';
          break;
        case 'auth/account-exists-with-different-credential':
          errorMessage = 'An account with this email already exists but with different sign-in credentials. Please try another method.';
          break;
        case 'auth/wrong-password':
          errorMessage = 'The password you entered is incorrect. Please try again.';
          break;
        case 'auth/user-not-found':
          errorMessage = 'No user found with this email. Would you like to sign up instead?';
          break;
        default:
          errorMessage = 'An unexpected error occurred. Please try again later.';
          break;
      }

      alert(errorMessage);
    } finally {
      setLoading(false); // Hide loader after the sign-in process completes
    }
  };

  if (loading) {
    return (
        <div className="loader-container">
          <ScaleLoader color="#1DB954" /> {/* Spotify green color */}
        </div>
    );
  }

  return (
      <div className="login-container">
        <div className="login-card">
          <h2>Welcome to Ambient Sounds</h2>
          <p>Sign in to continue</p>
          <button className="login-button" onClick={handleGoogleSignIn}>
            Sign in with Google
          </button>
          <SignUpSignIn /> {/* Include the email/password sign-up/sign-in form */}
          <Link to="/reset-password" className="reset-password-link">Forgot Password?</Link>
        </div>
      </div>
  );
};

export default Login;