import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { sendEmailVerification, signOut, updateProfile, onAuthStateChanged } from 'firebase/auth';
import './SignUpSignIn.css';
import { useNavigate } from 'react-router-dom';

const SignUpSignIn = () => {
    const navigate = useNavigate();
    const { signInWithEmail, signUpWithEmail, auth } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [isSigningUp, setIsSigningUp] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ email: '', password: '', name: '', surname: '' });

    useEffect(() => {
        if (!auth) {
            console.error("Firebase auth is not initialized correctly.");
            return;
        }

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                if (user.emailVerified || user.isAnonymous) {
                    navigate('/');
                } else {
                    signOut(auth)
                        .then(() => {
                            setMessage('Your email is not verified. Please verify your email to access the home page.');
                        })
                        .catch((error) => {
                            console.error('Error signing out:', error);
                        });
                }
            }
        });

        return () => unsubscribe();
    }, [auth, navigate]);

    const validateForm = () => {
        let valid = true;
        let emailError = '';
        let passwordError = '';
        let nameError = '';
        let surnameError = '';

        if (!email) {
            emailError = 'Email is required';
            valid = false;
        }

        if (!password) {
            passwordError = 'Password is required';
            valid = false;
        } else if (password.length < 6) {
            passwordError = 'Password must be at least 6 characters';
            valid = false;
        }

        if (isSigningUp) {
            if (!name) {
                nameError = 'Name is required';
                valid = false;
            }
            if (!surname) {
                surnameError = 'Surname is required';
                valid = false;
            }
        }

        setErrors({ email: emailError, password: passwordError, name: nameError, surname: surnameError });
        return valid;
    };

    const handleSignUp = async () => {
        if (!validateForm()) return;

        setLoading(true);
        setMessage(''); // Clear any previous messages
        try {
            const userCredential = await signUpWithEmail(email, password);
            const user = userCredential.user;

            await updateProfile(user, {
                displayName: `${name} ${surname}`,
            });

            await sendEmailVerification(user);

            await signOut(auth);

            setMessage('Verification email sent! Please check your inbox and verify your email before signing in.');

            // Wait for a short duration before navigating to ensure message is seen
            setTimeout(() => {
                navigate('/verify-email');
            }, 500);
        } catch (error) {
            const errorMessage = mapFirebaseError(error.code);
            setMessage(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleSignIn = async () => {
        if (!validateForm()) return;

        setLoading(true);
        setMessage(''); // Clear any previous messages
        try {
            const userCredential = await signInWithEmail(email, password);
            const user = userCredential.user;

            if (user.emailVerified) {
                setMessage('Sign-in successful!');
                navigate('/');
            } else {
                await sendEmailVerification(user);
                setMessage('Your email is not verified. A new verification email has been sent to your inbox.');
                await signOut(auth);
                navigate('/verify-email');
            }
        } catch (error) {
            const errorMessage = mapFirebaseError(error.code);
            setMessage(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const mapFirebaseError = (errorCode) => {
        switch (errorCode) {
            case 'auth/email-already-in-use':
                return 'This email is already associated with an account. Please sign in or use another email.';
            case 'auth/invalid-email':
                return 'The email address is not valid. Please enter a correct email address.';
            case 'auth/weak-password':
                return 'The password is too weak. Please enter a stronger password (at least 6 characters).';
            case 'auth/wrong-password':
                return 'The password you entered is incorrect. Please try again.';
            case 'auth/user-not-found':
                return 'No account found with this email. Please sign up or check the email entered.';
            case 'auth/too-many-requests':
                return 'Too many unsuccessful login attempts. Please try again later.';
            case 'auth/invalid-credential':
                return 'The login credentials are invalid. Please try again with correct credentials.';
            case 'auth/invalid-login-credentials':
                return 'The login credentials provided are invalid. Please check your email and password and try again.';
            case 'auth/network-request-failed':
                return 'Network error. Please check your internet connection and try again.';
            default:
                return 'An unexpected error occurred. Please try again later.';
        }
    };

    return (
        <div className="auth-container">
            <h2>{isSigningUp ? 'Sign Up' : 'Sign In'}</h2>

            {isSigningUp && (
                <>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        className={errors.name ? 'input-error' : ''}
                    />
                    {errors.name && <p className="error-message">{errors.name}</p>}
                    <input
                        type="text"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        placeholder="Surname"
                        className={errors.surname ? 'input-error' : ''}
                    />
                    {errors.surname && <p className="error-message">{errors.surname}</p>}
                </>
            )}

            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className={errors.email ? 'input-error' : ''}
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className={errors.password ? 'input-error' : ''}
            />
            {errors.password && <p className="error-message">{errors.password}</p>}

            <button onClick={isSigningUp ? handleSignUp : handleSignIn} disabled={loading}>
                {loading ? 'Processing...' : isSigningUp ? 'Sign Up' : 'Sign In'}
            </button>
            <button onClick={() => setIsSigningUp(!isSigningUp)} disabled={loading}>
                {isSigningUp ? 'Already have an account? Sign In' : 'Need an account? Sign Up'}
            </button>

            {message && <p className="auth-message">{message}</p>}
        </div>
    );
};

export default SignUpSignIn;