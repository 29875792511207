import React from 'react';
import './AboutUs.css';  // Import the specific CSS file for this page
import rauf from '../assets/rauf.jpg';  // Import your image file

const About = () => {
    return (
        <div className="about-page-container">
            {/* About Section */}
            <section className="about-section">
                <div className="about-content">
                    {/* Subtle Image Integration */}
                    <div className="about-image-container">
                        <img src={rauf} alt="Rauf Aghayev" className="about-image" />
                    </div>

                    <div className="about-text">
                        <p>
                            I'm Rauf Aghayev, a Senior Software Engineer currently working at Miro in Berlin, Germany. With years of experience in software development, I specialize in creating efficient and innovative technology solutions.
                        </p>
                        <p>
                            In the past, I have worked with leading tech companies, including Amazon, LogMeIn and 1&1, building robust software systems and enhancing cloud infrastructure.
                        </p>
                        <a href="https://www.linkedin.com/in/rauf-aghayev/" className="about-cta">Learn More</a> {/* Redirects to LinkedIn */}
                    </div>
                </div>
            </section>

            {/* Contact Section */}
            <section className="contact-section">
                <div className="container">
                    <h2>Contact</h2>
                    <p>Feel free to reach out to connect or discuss potential opportunities.</p>
                    <ul className="contact-info">
                        <li><strong>Email:</strong> <a href="mailto:agayevrauf@gmail.com">agayevrauf@gmail.com</a></li>
                        <li><strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/rauf-aghayev/">linkedin.com/in/rauf-aghayev</a></li>
                    </ul>
                </div>
            </section>

            {/* Footer Section */}
            <footer className="about-footer">
                <div className="container">
                    <p>&copy; 2024 Rauf Aghayev. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

export default About;