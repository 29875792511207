import React from 'react';
import { useNavigate } from 'react-router-dom';
import './VerifyEmailPage.css'; // Import the CSS file for styling

const VerifyEmailPage = () => {
    const navigate = useNavigate();

    const handleRedirectToLogin = () => {
        navigate('/login'); // Redirect to login page
    };

    return (
        <div className="verify-email-container">
            <h1>Please Verify Your Email</h1>
            <p>
                A verification email has been sent to your email address. Please check your inbox (and spam folder) and follow the instructions to verify your email.
            </p>
            <p>
                Once verified, you can sign in and start using the app.
            </p>

            {/* Button to redirect to login page */}
            <button onClick={handleRedirectToLogin} className="redirect-login-button">
                I have already verified my email
            </button>
        </div>
    );
};

export default VerifyEmailPage;